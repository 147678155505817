import React, { useState, useEffect } from 'react';

function Redirect({ userSub, userEmail }) { 

  useEffect(() => {
    if (userSub != null && userEmail != null) {
      window.location.href = `woz://callback?userSub=${userSub}&user_email=${encodeURIComponent(userEmail)}`;
    }
  }, [userSub, userEmail]);

  return (
    <div style={{ 
        display: 'flex', 
        flexDirection: 'column', 
        justifyContent: 'center', 
        alignItems: 'center', 
        height: '100vh', 
        width: '100vw', 
        backgroundColor: '#ffffff' 
      }}>

      {/* Lottie Animation */}
      <lottie-player 
          src="https://lottie.host/ebb4f118-598d-4a3c-acd5-6adb37c1c81b/YGu1VULqpA.json" 
          background="transparent" 
          speed="1" 
          style={{width: '400px', height: '200px'}}  // Increased size
          autoplay>
      </lottie-player>

      {/* Text */}
      <div style={{ 
          marginTop: '10px', 
          fontSize: '16px', 
      }}>
        Go back to the app if it doesn't open on its own.
      </div>

    </div>
  );
}

export default Redirect;
