import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import './App.css';
import SignIn from './SignIn';
import PaywallScreen from './PaywallScreen';
import Redirect from './Redirect';
import jwtDecode from 'jwt-decode';
import Settings from './Settings'
import '@aws-amplify/ui/styles.css';
import { withAuthenticator } from '@aws-amplify/ui-react';


const stripePromise = loadStripe('pk_test_DiaOutZesJHlHxPBw2rOUNrp');

// comment
function App() {
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [jwtToken, setJwtToken] = useState(null);
  const [userSub, setUserSub] = useState(null);
  const [hasActiveSubscription, setHasActiveSubscription] = useState(null);
  const [userEmail, setUserEmail] = useState(null)

  // comment
  useEffect(() => {
    checkUser();
  }, []);

  useEffect(() => {
    console.log("USER SUB: ", userSub);
    console.log("USER EMAIL: ", userEmail);
  }, [userSub, userEmail]); // This effect runs whenever userSub changes

  async function checkUser() {
    try {
      const session = await Auth.currentSession();
      const idToken = session.getIdToken().getJwtToken();
      const decodedToken = jwtDecode(idToken);
      console.log("Decided Token: ", decodedToken)
      
      console.log("User Sub: ", userSub)
      setJwtToken(idToken);
      setIsSignedIn(true);
  
      if (decodedToken.sub) {
        console.log("CALLING SET SUB: ", decodedToken.sub)

        setUserSub(decodedToken.sub); // this is the user's Cognito sub
        setUserEmail(decodedToken.email);
      
        // Check for active subscriptions after setting userSub
        // fetch(`${process.env.REACT_APP_API_URL}/get-subscription?user_sub=${userSub}`)
        // .then(response => response.json())
        // .then(data => {
        //   console.log("DATA, ", data)
        //   if (data.subscriptions && data.subscriptions.length > 0) {
        //     setHasActiveSubscription(true);
        //   }
        // });
      }
    
    } catch (err) {
      console.log(err);
      setIsSignedIn(false);
    }
  }
  
  const PrivateRoutes = () => {
    const navigate = useNavigate();
  
    useEffect(() => {
      if (!isSignedIn || !hasActiveSubscription) {
        navigate('/');
      } else {
        navigate('/settings');
      }
    }, [isSignedIn, hasActiveSubscription, navigate]);
  
    return (
      <Routes>
        <Route path="/" element={<Redirect userSub={userSub} userEmail={userEmail} />} />
        <Route path="/settings" element={<Settings userSub={userSub} />} />
      </Routes>
    );
  };
  


  return (
    <Elements stripe={stripePromise}>
      <Router>
        <PrivateRoutes />
      </Router>
    </Elements>
  );
}
export default withAuthenticator(App);


