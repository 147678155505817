import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import {ElementsConsumer, CardElement} from '@stripe/react-stripe-js';
import './PaywallScreen.css';

class CheckoutForm extends React.Component {
  handleSubmit = async (event) => {
    event.preventDefault();

    const {stripe, elements, selectedPlan, handlePaymentSuccess, handlePaymentError, userSub} = this.props;

    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);
    
    // Create a payment method
    const {paymentMethod, error} = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement
    });
    
    if (error) {
      handlePaymentError(error.message);
    } else {
      console.log("USER SUB: ", userSub)
      console.log(process.env)
      // Send paymentMethod.id to your server (here it's /create-subscription)
      fetch(`${process.env.REACT_APP_API_URL}/create-subscription`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          plan: selectedPlan,
          payment_method: paymentMethod.id,
          user_sub: userSub // Passing user's Cognito sub
        })
      }).then(response => {
        if (!response.ok) {
          response.json().then(json => {
            handlePaymentError(json.error);
          });
        } else {
          handlePaymentSuccess();
        }
      });
    }
  };

  render() {
    return (
      <form onSubmit={this.handleSubmit} className="payment-form">
        <CardElement />
        <button disabled={!this.props.stripe} className="submit-button">Submit</button>
      </form>
    );
  }
}

function PaywallScreen({ userSub }) { // Adding userSub prop
  const [selectedPlan, setSelectedPlan] = useState("");
  const [plans, setPlans] = useState([]);
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [paymentError, setPaymentError] = useState("");
  const [hasActiveSubscription, setHasActiveSubscription] = useState(false); // Adding state to track active subscription

  console.log(process.env.REACT_APP_API_URL)
  console.log(process.env)

  useEffect(() => {
    window.location.href = `woz://callback?userSub=${userSub}`;
  }, [userSub]);


  const handleChange = (e) => {
    setSelectedPlan(e.target.value);
  }

  const handlePaymentSuccess = () => {
    setPaymentSuccess(true);
    setPaymentError("");
    setHasActiveSubscription(true); // Update active subscription status on successful payment
  }

  const handlePaymentError = (error) => {
    setPaymentSuccess(false);
    setPaymentError(error);
  }

  if (hasActiveSubscription) {
    return <p>Already active!</p>
  }

  return (
    <div className="paywall-container">
      <h2 className="paywall-header">Payment Options</h2>
      {paymentSuccess ? 
        <p>Payment Successful!</p>
      : paymentError ? 
        <p>{paymentError}</p>
      :
        <React.Fragment>
          <div className="plans-container">
          {Array.isArray(plans) ? 
            plans.map(plan => (
              <label key={plan.id} className="plan">
                <input 
                  type="radio" 
                  name="plan" 
                  value={plan.id} 
                  checked={selectedPlan === plan.id}
                  onChange={handleChange}
                />
                <div className="plan-content">
                  <span className="plan-name">{plan.name}</span> 
                  <span className="plan-price">${plan.amount}</span>
                </div>
              </label>
            ))
            : 
            <p>No plans available</p>
          }
          </div>
          <ElementsConsumer>
            {({stripe, elements}) => (
              <CheckoutForm 
                stripe={stripe} 
                elements={elements} 
                selectedPlan={selectedPlan}
                handlePaymentSuccess={handlePaymentSuccess}
                handlePaymentError={handlePaymentError}
                userSub={userSub} // Passing userSub to CheckoutForm
              />
            )}
          </ElementsConsumer>
        </React.Fragment>
      }
    </div>
  );
}

export default PaywallScreen;
