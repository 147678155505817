import React, { useState, useEffect } from 'react';

const Settings = ({ userSub }) => {
  const [subscriptions, setSubscriptions] = useState([]);

  useEffect(() => {
    // Fetch subscriptions
    fetch(`${process.env.REACT_APP_API_URL}/get-subscription?user_sub=${userSub}`)
      .then(response => response.json())
      .then(data => {
        console.log(data.subscriptions)
        setSubscriptions(data.subscriptions);
      });
  }, [userSub]);

  const handleCancelSubscription = (subscriptionId) => {
    // Cancel subscription
    fetch(`${process.env.REACT_APP_API_URL}/cancel-subscription`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ subscription_id: subscriptionId })
    })
      .then(() => {
        setSubscriptions(subscriptions.filter(sub => sub.id !== subscriptionId));
      });
  };

  const handleGoBack = () => {
    // Replace "woz" with your app's custom scheme and "callback" with the specific handling path
    window.location.href = `woz://callback?userSub=${userSub}`;
  }

  return (
    <div className="settings-container">
      <h2>Active Subscriptions</h2>
      {subscriptions.map(sub => (
        <div key={sub} className="subscription">
          <p>Plan: {sub}</p>
          <button onClick={() => handleCancelSubscription(sub)}>Cancel Subscription</button>
        </div>
      ))}
      <button onClick={handleGoBack}>Open App With Auth</button>
    </div>
  );
};

export default Settings;
